import React, { Component } from "react";
import ContentBody from "../components/ContentBody";
import ArticleList from "../components/ArticleList";

class PageForDevs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ContentBody>
        <ArticleList articles={require("../products/dev_tools.json")} />
      </ContentBody>
    );
  }
}

export default PageForDevs;
