import React from "react";
import { Link } from "react-router-dom";
import Routes from "../constants/routes.json";
import styles from "./Footer.module.css";

function Footer(props) {
  const { changeLanguage } = props;

  return (
    <div className={styles.Wrap}>
      <div className={styles.LangSelect}>
        <img
          onClick={() => changeLanguage("de")}
          className={styles.LangItem}
          alt="Deutsch"
          src={require("../media/lang_german.svg")}
        />
        <img
          onClick={() => changeLanguage("en")}
          className={styles.LangItem}
          alt="English"
          src={require("../media/lang_english.svg")}
        />
      </div>
      <p>
        Copyright 2019 Uelstudios
        <Link to={Routes.legal}>Impressum</Link>
      </p>
    </div>
  );
}

export default Footer;
