class Language {
  static GetString(key) {
    const currentLanguage = localStorage.getItem("language");

    var langIndex = 0;
    if (currentLanguage === "en") langIndex = 1;

    return require("../constants/strings.json")[langIndex][key];
  }

  static GetLangId() {
    const langName = localStorage.getItem("language");

    //Name to id. (German = 0, English = 1)
    if (langName === "en") return 1;
    return 0;
  }
}

export default Language;
