import React, { Component } from "react";
import { Link } from "react-router-dom";
import ContentBody from "../components/ContentBody";
import Routes from "../constants/routes.json";

class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ContentBody>
        <h2>404 - Seite wurde nicht gefunden</h2>
        <p>Mehr wissen wir auch nicht :(</p>
        <br />
        <Link to={Routes.home}>Zur Startseite</Link>
      </ContentBody>
    );
  }
}

export default Page404;
