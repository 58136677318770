import React from "react";

import styles from "./SocialLink.module.css";
import Language from "../utils/Language";

function SocialLink(props) {
  return (
    <div className={styles.Wrap}>
      <p className={styles.Title}>{Language.GetString("social_title")}</p>
      <div className={styles.ButtonGroup}>
        <div className={styles.Item}>
          <a
            className={styles.Button}
            style={{ backgroundColor: "#e91e63" }}
            href="https://assetstore.unity.com/publishers/28565"
          >
            {Language.GetString("social_unityas")}
          </a>
        </div>
        <div className={styles.Item}>
          <a
            className={styles.Button}
            style={{ backgroundColor: "#689f38" }}
            href="https://play.google.com/store/apps/dev?id=5079797572205129369"
          >
            {Language.GetString("social_googleplay")}
          </a>
        </div>
        <div className={styles.Item}>
          <a
            className={styles.Button}
            href="https://www.youtube.com/channel/UCRGkeAE958X-JiQa5ukWpSw/videos"
          >
            {Language.GetString("social_youtube")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default SocialLink;
