import React from "react";

import styles from "./ContentBody.module.css";

function ContentBody(props) {
  const style = {};

  if (props.noTopPadding) style.paddingTop = "0px";

  return (
    <div className={styles.Wrap} style={style}>
      {props.children}
    </div>
  );
}

export default ContentBody;
