import React, { Component } from "react";
import { Link } from "react-router-dom";

import styles from "./NewProductItem.module.css";
import Language from "../utils/Language";
import Routes from "../constants/routes.json";

class NewProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={styles.Wrap}>
        <div className={styles.Head}>
          <p className={styles.TextNew}>
            {Language.GetString("new_product_title")}
          </p>
          <div className={styles.SubHead}>
            <img
              className={styles.Symbol}
              alt="Symbol"
              src="/img/game_synonym/symbol.jpg"
            />
            <p className={styles.TextName}>Synonym - Ein anderes Wort für</p>
          </div>
        </div>
        <video
          poster="/img/game_synonym/video_trailer_thumb.jpg"
          className={styles.Video}
          src="/img/game_synonym/video_trailer.mp4"
          controls
        >
          {Language.GetString("new_product_trailer_error")}
        </video>
        <div className={styles.ButtonWrap}>
          <Link className={styles.Button} to={Routes.games}>
            {Language.GetString("new_product_button")}
          </Link>
        </div>
      </div>
    );
  }
}

export default NewProductItem;
