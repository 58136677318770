import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import styles from "./Header.module.css";
import Language from "../utils/Language";
import Routes from "../constants/routes.json";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { navVisible: false };

    this.toggleNav = this.toggleNav.bind(this);
  }

  //Used by Mobile Devices
  toggleNav() {
    this.setState({ navVisible: !this.state.navVisible });
  }

  render() {
    const { navVisible } = this.state;

    return (
      <div className={styles.Wrap}>
        <div className={styles.ControlWrap}>
          <div className={styles.Left}>
            <Link to={Routes.home}>
              <img
                alt="Uelstudios"
                src={require("../media/branding/uelstudios_logo.png")}
              />
            </Link>
          </div>
          <div className={styles.Right}>
            <div className={styles.MobileMenuButton} onClick={this.toggleNav}>
              <img alt="Menu" src={require("../media/button_menu.svg")} />
            </div>
            <NavLink
              exact
              to={Routes.home}
              activeClassName={styles.NavButtonActive}
              className={styles.NavButton}
            >
              {Language.GetString("nav_new")}
            </NavLink>
            <NavLink
              to={Routes.games}
              activeClassName={styles.NavButtonActive}
              className={styles.NavButton}
            >
              {Language.GetString("nav_games")}
            </NavLink>
            <NavLink
              to={Routes.dev}
              activeClassName={styles.NavButtonActive}
              className={styles.NavButton}
            >
              {Language.GetString("nav_dev")}
            </NavLink>
            <NavLink
              to={Routes.about}
              activeClassName={styles.NavButtonActive}
              className={styles.NavButton}
            >
              {Language.GetString("nav_about")}
            </NavLink>
          </div>
        </div>
        <div
          className={
            navVisible ? styles.MobileNavigation : styles.MobileNavigationHidden
          }
          onClick={this.toggleNav}
        >
          <NavLink
            exact
            to={Routes.home}
            activeClassName={styles.NavButtonActive}
            className={styles.NavButton}
          >
            {Language.GetString("nav_new")}
          </NavLink>
          <NavLink
            to={Routes.games}
            activeClassName={styles.NavButtonActive}
            className={styles.NavButton}
          >
            {Language.GetString("nav_games")}
          </NavLink>
          <NavLink
            to={Routes.dev}
            activeClassName={styles.NavButtonActive}
            className={styles.NavButton}
          >
            {Language.GetString("nav_dev")}
          </NavLink>
          <NavLink
            to={Routes.about}
            activeClassName={styles.NavButtonActive}
            className={styles.NavButton}
          >
            {Language.GetString("nav_about")}
          </NavLink>
          <img alt="Close" src={require("../media/button_cancel.svg")} />
        </div>
      </div>
    );
  }
}

export default Header;
