import React, { Component } from "react";
import ProductArticle from "./ProductArticle";

import styles from "./ProductArticle.module.css";

class ArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { articles } = this.props;

    var items = [];

    articles.forEach(article => {
      items.push(<ProductArticle key={article.id} article={article} />);

      if ((items.length + 1) / 2 < articles.length)
        items.push(
          <div key={article.id + "_div"} className={styles.ArticleDivider} />
        );
    });
    return <div>{items}</div>;
  }
}

export default ArticleList;
