import React from "react";
import { Link } from "react-router-dom";

import styles from "./MoreGames.module.css";
import Language from "../utils/Language";
import Routes from "../constants/routes.json";

function MoreGames(props) {
  return (
    <div className={styles.Wrap}>
      <p className={styles.Title}>{Language.GetString("more_games_title")}</p>
      <Link to={Routes.games} className={styles.Button}>
        {Language.GetString("more_games")}
      </Link>
    </div>
  );
}

export default MoreGames;
