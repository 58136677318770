import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PageHome from "./pages/PageHome";
import Header from "./components/Header";
import PageGames from "./pages/PageGames";
import Page404 from "./pages/Page404";
import Footer from "./components/Footer";
import PageForDevs from "./pages/PageForDevs";
import PageAbout from "./pages/PageAbout";
import Routes from "./constants/routes.json";
import PageLegalDisclosure from "./pages/PageLegalDisclosure";

class App extends Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(newLanguage) {
    localStorage.setItem("language", newLanguage);
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <Header key="header" />
        <Switch>
          <Route exact path={Routes.home} component={PageHome} />
          <Route exact path={Routes.games} component={PageGames} />
          <Route exact path={Routes.dev} component={PageForDevs} />
          <Route exact path={Routes.about} component={PageAbout} />
          <Route exact path={Routes.legal} component={PageLegalDisclosure} />

          <Route component={Page404} />
        </Switch>
        <Footer changeLanguage={this.changeLanguage} />
      </div>
    );
  }
}

export default App;
