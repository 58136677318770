import React, { Component } from "react";
import ContentBody from "../components/ContentBody";
import ProductArticle from "../components/ProductArticle";

//This is the divider used between Articles. Reused because: Easy :-D
import stylesDivider from "../components/ProductArticle.module.css";
import SocialLink from "../components/SocialLink";

class PageAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const info = require("../constants/paul.json");
    info.images = [
      {
        thumb: require("../media/paul.jpg"),
        full: require("../media/paul_full.jpg")
      }
    ];

    return (
      <ContentBody>
        <ProductArticle article={info} />
        <div className={stylesDivider.ArticleDivider} />
        <SocialLink />
      </ContentBody>
    );
  }
}

export default PageAbout;
