import React, { Component } from "react";

import styles from "./CustomerRatings.module.css";
import Language from "../utils/Language";

class CustomerRatings extends Component {
  constructor(props) {
    super(props);
    this.state = { currentQuote: "" };

    this.newQuoteInterval = undefined;
  }

  componentDidMount() {
    this.newQuoteInterval = setInterval(() => {
      this.showRandomQuote();
    }, 5000);

    this.showRandomQuote();
  }

  showRandomQuote() {
    const customerQuotes = require("../constants/customer_quotes.json");
    const quotes = customerQuotes[Language.GetLangId()];
    const index = Math.floor(Math.random() * quotes.length);

    this.setState({
      currentQuote: quotes[index]
    });
  }

  componentWillUnmount() {
    clearInterval(this.newQuoteInterval);
  }

  render() {
    const { currentQuote } = this.state;

    return (
      <div className={styles.Wrap}>
        <p className={styles.Title}>
          {Language.GetString("what_players_think")}
        </p>
        <p className={styles.Quote}>{currentQuote}</p>
      </div>
    );
  }
}

export default CustomerRatings;
