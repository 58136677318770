import React, { Component } from "react";
import ContentBody from "../components/ContentBody";
import ArticleList from "../components/ArticleList";

class PageGames extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ContentBody>
        <ArticleList articles={require("../products/games.json")} />
      </ContentBody>
    );
  }
}

export default PageGames;
