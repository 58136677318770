import React, { Component } from "react";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";
import styles from "./ProductArticle.module.css";
import Language from "../utils/Language";

class ProductArticle extends Component {
  constructor(props) {
    super(props);
    this.state = { viewerImageIndex: undefined };

    this.hideImageViewer = this.hideImageViewer.bind(this);
  }

  showYouTubeVideo(videoUrl) {
    window.open(videoUrl);
  }

  showImageViewer(imageIndex) {
    this.setState({ viewerImageIndex: imageIndex });
  }

  hideImageViewer() {
    this.setState({ viewerImageIndex: undefined });
  }

  render() {
    const { viewerImageIndex } = this.state;

    //Get Article
    const { languages, store, videos, images, symbol } = this.props.article;

    //Language
    var selectedLanguageIndex = Language.GetLangId();

    //Clamp Language. Use first if requested lang is not available
    if (selectedLanguageIndex >= languages.length) {
      selectedLanguageIndex = 0;
    }

    //Informations
    const selectedLanguage = languages[selectedLanguageIndex];
    const name = selectedLanguage.name;
    const price = selectedLanguage.price;
    const description = selectedLanguage.description;

    //Media
    var thumbnails = [];

    //Add Videos
    thumbnails.push(
      videos.map(vid => (
        <img
          key={vid.thumb}
          alt=""
          src={vid.thumb}
          onClick={() => this.showYouTubeVideo(vid.src)}
        />
      ))
    );

    //Add Images
    thumbnails.push(
      images.map((img, index) => (
        <img
          key={img.thumb}
          alt=""
          src={img.thumb}
          onClick={() => this.showImageViewer(index)}
        />
      ))
    );

    //Array for Image Viewer
    const viewerImages = images.map(img => {
      return { src: img.full, alt: "" };
    });

    //Render
    return (
      <div className={styles.Wrap}>
        <div className={styles.Head}>
          <div className={styles.Title}>
            {symbol && <img alt="Symbol" src={symbol} />}
            {name}
          </div>
        </div>
        <div className={styles.Body}>{description}</div>
        <div className={styles.Foot}>
          <div className={styles.MediaCarousel}>
            <div className={styles.MediaCarouselContainer}>{thumbnails}</div>
          </div>
          {store && (
            <div className={styles.Redirect}>
              <p className={styles.Price}>{price}</p>
              <a
                href={store.href}
                className={styles.Button}
                style={{ backgroundColor: store.color }}
              >
                {store.name}
              </a>
            </div>
          )}
        </div>
        <Viewer
          visible={viewerImageIndex !== undefined}
          activeIndex={viewerImageIndex}
          onClose={this.hideImageViewer}
          rotatable={false}
          scalable={false}
          onMaskClick={this.hideImageViewer}
          noImgDetails-
          images={viewerImages}
        />
      </div>
    );
  }
}

export default ProductArticle;
