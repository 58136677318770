import React, { Component } from "react";
import ContentBody from "../components/ContentBody";
import MoreGames from "../components/MoreGames";
import CustomerRatings from "../components/CustomerRatings";

//This is the divider used between Articles. Reused because: Easy :-D
import stylesDivider from "../components/ProductArticle.module.css";
import NewProductItem from "../components/NewProductItem";
import SocialLink from "../components/SocialLink";

class PageHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <NewProductItem />
        <ContentBody noTopPadding>
          <MoreGames />
          <div className={stylesDivider.ArticleDivider} />
          <SocialLink />
          <div className={stylesDivider.ArticleDivider} />
          <CustomerRatings />
        </ContentBody>
      </div>
    );
  }
}

export default PageHome;
